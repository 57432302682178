@import "../../../../assets/styles/vars";
@import "../../../../assets/styles/functions";


@font-face {
  font-family: "Segoe UI";
  src: url("../../../../assets/fonts/fonts-files/Segoe\ UI.ttf");
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");

.controls {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 1.5rem;
}


.confirm {
  background: #fff;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 104;
  padding: 1.25rem 2rem;
  border-radius: 5px;
  min-height: 9.375rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  max-width: 50rem;
  min-width: 30rem;
}

.btn {
  width: rem(120);
  height: rem(50);
  border-radius: rem(7);
  font-weight: 500;
  font-size: rem(18);
  line-height: rem(20);
  color: #fff;
  padding: rem(14) rem(23) rem(16) rem(23);
  transition: all 0.3s ease;
  border-radius: rem(10);
}

.btnDisagree {
  background: #ececec;
  color: #222b45;

  &:not([disabled]):hover {
    background: darken(#ececec, 5%);
  }
}

@mixin confirm($width, $height) {
  background: #fff;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 101;
  border-radius: 5px;
  min-height: 9.375rem;
  width: ($width);
  height: ($height);
  font-family: "Roboto", sans-serif;
}